import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { VGTVCategory, type Vendor } from '../../types.js';
import { deeplinksSupported } from '../../utils/device.js';
import { config } from '../../config.js';
import { getAssetShortPath } from '../../player/playerAssetHelpers.js';

import { getCategoryShortPath } from './categoryHelpers.js';

export const BRANDSTUDIO_ROUTE_PREFIX = '/annonsorinnhold';

export interface AssetPagePathParams {
  vendor: Vendor;
  assetId: number;
  titleShortPath?: string;
  start?: number;
  time?: number;
}

export interface AssetPagePathParamsV2 {
  asset: SvpAssetAdditional;
  start?: number;
  time?: number;
  skipDeeplinkCheck?: true;
  version?: 'v2' | 'v1';
}

export const addTimeOffset = ({
  route,
  time,
  platform = 'web',
}: {
  route: string;
  time?: number;
  platform?: 'web' | 'app';
}) => {
  let t = time ? String(time) : undefined;

  if (t && platform === 'web') {
    t += 's';
  }

  if (t) {
    const glueChar = route.includes('?') ? '&' : '?';

    return `${route}${glueChar}t=${t}`;
  }

  return route;
};

export const getAssetPagePathV2 = ({
  asset,
  start,
  time,
  skipDeeplinkCheck,
  version = 'v2',
}: AssetPagePathParamsV2): string => {
  const titleShortPath = getAssetShortPath(asset);

  if (version === 'v2' && asset.provider === 'vgtv') {
    if (!skipDeeplinkCheck && deeplinksSupported) {
      return addTimeOffset({
        route: `video:${asset.id}?provider=${config.provider}`,
        time,
        platform: 'app',
      });
    }
    if (
      asset.category.id === 121 &&
      asset.additional.metadata.contentType === 'documentary'
    ) {
      return addTimeOffset({
        route: `/dokumentar/${titleShortPath}?id=${asset.id}`,
        time,
      });
    }
    if (asset.category.id === VGTVCategory.NEWS) {
      return addTimeOffset({
        route: `/nyheter/${titleShortPath}?id=${asset.id}`,
        time,
      });
    }
    if (asset.series) {
      const categoryShortPath = getCategoryShortPath(asset.category);

      const route = `/serier/${categoryShortPath}/s${asset.series.seasonNumber}e${asset.series.episodeNumber}/${titleShortPath}?id=${asset.id}`;

      return addTimeOffset({
        route,
        time,
      });
    }
  }

  return getAssetPagePath(
    {
      vendor: asset.provider,
      assetId: asset.id,
      titleShortPath,
      start,
      time,
    },
    skipDeeplinkCheck
  );
};

export function getAssetPagePath(
  { vendor, assetId, titleShortPath, start, time }: AssetPagePathParams,
  skipDeeplinkCheck = false
): string {
  if (!skipDeeplinkCheck && deeplinksSupported && vendor !== 'brandstudio') {
    return addTimeOffset({
      route: `video:${assetId}?provider=${config.provider}`,
      time,
      platform: 'app',
    });
  }

  let route = `/video/${assetId}`;

  if (titleShortPath) {
    route += `/${titleShortPath}`;
  }

  if (start) {
    route += `/${start}`;
  }

  if (vendor === 'brandstudio') {
    route = BRANDSTUDIO_ROUTE_PREFIX + route;
  }

  return addTimeOffset({ route, time });
}
