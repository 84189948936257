import type {
  HasSessionSuccessResponse,
  Identity,
} from '@schibsted/account-sdk-browser/src/identity';
import type { Monetization } from '@schibsted/account-sdk-browser/src/monetization';

import { config } from '../config';

let identity: Identity | undefined;
let monetization: Monetization | undefined;

async function getIdentityInstance() {
  if (!identity) {
    const { Identity } = await import('@schibsted/account-sdk-browser');
    const { clientId, env, sessionDomain } = config.schibstedAccount;

    identity = new Identity({
      clientId,
      redirectUri: `${window.location.origin}/login-callback`,
      env,
      sessionDomain,
    });

    if (config.env === 'development') {
      identity.clearCachedUserSession();
    }

    if (config.env === 'production') {
      identity.enableVarnishCookie({ domain: 'vg.no' });
    }
  }

  return identity;
}

async function getMonetizationInstance() {
  if (!monetization) {
    const { Monetization } = await import('@schibsted/account-sdk-browser');
    const { clientId, env, sessionDomain } = config.schibstedAccount;

    monetization = new Monetization({
      clientId,
      redirectUri: `${window.location.origin}/login-callback`,
      env,
      sessionDomain,
    });
  }

  return monetization;
}

async function clearSessionCache() {
  const identity = await getIdentityInstance();

  identity.clearCachedUserSession();
}

const DEFAULT_LOGIN_SOURCE = 'login-flow';

function getLoginOptions(source?: string) {
  const origin = new URL(window.location.href);
  origin.searchParams.set('utm_source', source || DEFAULT_LOGIN_SOURCE);

  return {
    state: origin.pathname + origin.search, // no need to encodeURIComponent here - it's done in the SDK
  };
}

async function login(source?: string) {
  const identity = await getIdentityInstance();

  identity.login(getLoginOptions(source));
}

async function showContinueAs() {
  const identity = await getIdentityInstance();

  return identity.showSimplifiedLoginWidget(getLoginOptions('continue-as'));
}

async function getUserId() {
  const identity = await getIdentityInstance();

  return identity.getUserId().catch(() => null);
}

async function getUserUuid() {
  const identity = await getIdentityInstance();

  return identity.getUserUuid().catch(() => null);
}

async function isUserConnected() {
  const identity = await getIdentityInstance();

  return identity.isConnected().catch(() => false);
}

async function getSessionData(): Promise<HasSessionSuccessResponse | null> {
  const identity = await getIdentityInstance();

  return identity
    .hasSession()
    .then((sessionData) => (sessionData as HasSessionSuccessResponse) ?? null)
    .catch(() => null);
}
/**
 * The signature is a concatenation of an HMAC SHA-256 signature string, a dot (.) and a base64url encoded JSON object (session).
 *
 * @see {@link http://techdocs.spid.no/sdks/js/response-signature-and-validation/|Documentation}
 */
async function getUserSignature(): Promise<string | null> {
  const sessionData = await getSessionData();
  return sessionData?.sig ?? null;
}

async function isLoggedIn() {
  const identity = await getIdentityInstance();

  return identity.isLoggedIn();
}

async function logout(redirectUri = window.location.href) {
  const identity = await getIdentityInstance();

  identity.logout(redirectUri);
}

const getAccountUrl = async () => {
  const identity = await getIdentityInstance();

  return identity.accountUrl();
};

export {
  getIdentityInstance,
  getMonetizationInstance,
  login,
  getUserId,
  getUserUuid,
  isUserConnected,
  showContinueAs,
  isLoggedIn,
  clearSessionCache,
  logout,
  getAccountUrl,
  getUserSignature,
  getSessionData,
};
